import React from "react";

const GalleryCard2 = ({ Image, Title, Content }) => (
  <div className="gallery2-card">
    <img src={process.env.PUBLIC_URL + Image} alt={Content} />
    <h4>Lorem Lorem Ipsum</h4>
  </div>
);

const Gallery2 = ({ images }) => {
  return (
    <>
      <div className="gallery-section mb-5">
        <div className="gallery-section-introduction">
          <div className="gallery-container">
            <div className="gallery2-cards pt-4">
              {images.map((award, index) => (
                <GalleryCard2 key={index} {...award} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery2;
