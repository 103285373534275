import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const FooterThree = () => {
  return (
    <footer>
      <div className="footerThree">
        <div className="footerThree-container">
          <div className="footerThree-logo">
            <img src="/images/logo.png" alt="logo"></img>
            <p>
              Union Fabrics (Pvt.) Limited intends to be the best manufacturer
              of the textile products by assuring quality in products, service
              and enviromental aiming at customers' stakeholders' satisfaction
              by complying with regulatory and other requirements for continual
              improvement.
            </p>
          </div>
          <div className="footerThree-menu">
            <div className="footerThree-menu-border">
              <div className="vertical-line"></div>
              <div className="circle"></div>
            </div>

            <nav className="footermenu-nav">
              <ul className="footermenu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact"}>About Us</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact"}>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact"}>Products</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footerThree-menu">
            <div className="footerThree-menu-border">
              <div className="vertical-line"></div>
              <div className="circle"></div>
            </div>

            <nav className="footermenu-nav">
              <ul className="footermenu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"}>Production</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact"}>
                    Social Compliance
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact"}>
                    Strategic Partners
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footerThree-menu">
            <div className="footerThree-menu-border">
              <div className="vertical-line"></div>
              <div className="circle"></div>
            </div>

            <nav className="footermenu-nav">
              <ul className="footermenu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    Privacy Policies
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact"}>
                    Terms and Conditions
                  </Link>
                </li>
              </ul>
              <ul className="footermenu-icons">
                <li>
                  <Link to="https://facebook.com/">
                    <FaFacebookF />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/">
                    <FaInstagram />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="copyrights">
          <p>Designed by</p>
          <img src="/images/systechlogic-logo.png" width={100} alt="systech"></img>
        </div>
      </div>
    </footer>
  );
};

export default FooterThree;
