import React from "react";

const GalleryCard4 = ({ Image, Title, Content }) => (
  <div className="gallery2-card" >
    <img src={process.env.PUBLIC_URL + Image} alt={Content} />
  </div>
);

const Gallery4 = ({ images }) => {
  return (
    <>
      <div className="gallery-section mb-5">
        <div className="gallery-section-introduction">
          <div className="gallery-container">
            <div className="gallery2-cards pt-4" style={{gap:"20px"}}>
              {images.map((award, index) => (
                <GalleryCard4 key={index} {...award} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery4;