import React, {useRef, useState} from 'react';
// import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
        </Alert>
    )
}

const FormTwo = () => {


    const form = useRef();

    const [formData, setFormData] = useState({

        name: '',
        email: '',
        phone: '',
        message: '',
        companyName: '',
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

    const [ result, showresult ] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await fetch('https://union.kidznteen.pk/public/api/form_store', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          });
          if (response.ok) {
            console.log('Data submitted successfully');
            form.current.reset();
            // Optionally, you can reset the form after successful submission
            setFormData({
              name: '',
              email: ''
              // Reset other fields as needed
            });
          } else {
            console.error('Failed to submit data');
          }
        } catch (error) {
          console.error('Error submitting data:', error);
        }
      };

        setTimeout(() => {
            showresult(false);
        }, 5000);


    return (
        <form ref={form} onSubmit={handleSubmit} className="axil-contact-form" style={{display:"flex", flexDirection:"column"}}>
            <h5 className='form-heading '>Send us a message</h5>
        <div className="form-group">
            <label>First & Last Name <span style={{color:"red"}}>*</span></label>
            <input type="text" className="form-control" name="name" required  onChange={handleChange}/>
        </div>
        <div className='tel-email'>
        <div className="form-group">
            <label>Phone <span style={{color:"red"}}>*</span></label>
            <input type="tel" className="form-control" name="phone" pattern="^\+?\d+$" title="Please enter only digits, plus (+), or hyphen (-)" onChange={handleChange} required />
        </div>
        <div className="form-group">
            <label>Email <span style={{color:"red"}}>*</span></label>
            <input type="email" className="form-control" name="email" onChange={handleChange} required />
        </div>
        </div>
        <div className="form-group">
            <label>Company Name (Optional)</label>
            <input type="text" className="form-control" name="companyName" onChange={handleChange} />
        </div>
        <div className="form-group mb--40">
            <label>Mesage <span style={{color:"red"}}>*</span></label>
            <textarea className="form-control" name="message" rows="4" onChange={handleChange} required></textarea>
            
        </div>
        <div className="form-group">
            <button type="submit" className="form-button form-button-primary" name="submit-btn">Contact Us Now</button>
        </div>
        <div className="form-group">
            {result ? <Result /> : null}
        </div>

    </form>
    )
}

export default FormTwo;