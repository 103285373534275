import React, { useRef, useState, useEffect } from "react";
import { BiSolidVolumeFull, BiSolidVolumeMute } from "react-icons/bi";
import YouTube from "react-youtube";

// Loader
import Loader from "react-js-loader";

const BannerFive = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [banner, setBanner] = useState(null);
  const [muted, setMuted] = useState(false);

  const playerRef = useRef(null);

  const toggleMute = () => {
    if (playerRef.current) {
      const isMuted = playerRef.current.isMuted();
      if (isMuted) {
        playerRef.current.unMute();
      } else {
        playerRef.current.mute();
      }
      setMuted(!muted);
    }
  };

  const opts = {
    width: "100%",
    height: "100%",
    playerVars: {
      autoplay: 1,
      showinfo: 0, // Hide video title and uploader information
      rel: 0, // Do not show related videos at the end
      controls: 0,
      loop: 1,
      playlist: banner,
      mute:1
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bannerResult = await fetch(
          "https://union.kidznteen.pk/public/api/slider"
        );

        if (!bannerResult.ok) {
          throw new Error("Network response was not ok for Menu Items");
        }

        const bannerData = await bannerResult.json();

        const foundBanner = bannerData.find(
          (page_id) => page_id.Slider_Id === 1
        );
        setBanner(foundBanner.Link);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="Loader-Custom">
        <Loader
          type="bubble-ping"
          bgColor={"#DC9553"}
          color={"#000"}
          size={100}
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div
      className="banner banner-style-5 in-line"
      style={{
        backgroundColor: "black",
        height: "100%",
      }}
    >
      {banner && (
        <YouTube
          videoId={banner}
          opts={opts}
          className="youtube-video" 
          onReady={(event) => {
            event.target.playVideo(); // Autoplay the video on load
            // event.target.mute(); // Mute the video on load
            playerRef.current = event.target; // Store the player instance
          }}
        />
      )}

      {/* <video
        autoPlay
        loop
        className="video-background"
        playsInline
        muted
        // onClick={toggleMute} // Toggle mute/unmute on click
        ref={videoRef}
        // poster={process.env.PUBLIC_URL + "/images/banner/banner-bg-1.png"}
      >
        <source
          src={process.env.PUBLIC_URL + "/video/union-fabrics.mp4"}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-7">
            <div className="banner-content">
              <h4 className="title">
                Reshaping the future of the textile industry.<br></br>For the
                planet and the people.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="mute-icon" onClick={toggleMute}>
        {muted ? <BiSolidVolumeFull /> : <BiSolidVolumeMute />}
      </div>
    </div>
  );
};

export default BannerFive;
