import React, { useEffect, useState } from "react";

// import elements
import SectionTitle from "../elements/section-title/SectionTitle";

// import common components
import SEO from "../common/SEO";

// Loader
import Loader from "react-js-loader";

// import components
import BannerFive from "../component/banner/BannerFive";
import HistorySection from "../component/history/History";
import CTASection from "../component/cta/CTA";
import CertificateSection from "../component/certifications/Certificate";
import ContactUsSection from "../component/contact/ContactUsSection";
import Slider from "../component/slider/Slider";

const Home = () => {
  const [aboutUs, setAboutUs] = useState(null);
  const [history, setHistory] = useState(null);
  const [product, setProduct] = useState(null);
  const [seo, setSEO] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [banner, setBanner] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API call
        const response = await fetch(
          "https://union.kidznteen.pk/public/api/pages/1"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok for Homepage Content");
        }
        const result2 = await response.json();
        setSEO(result2);

        setAboutUs(
          await result2[0].Sections.find((section) => section.PH_Id === 1)
        );

        setHistory(
          await result2[0].Sections.find((section) => section.PH_Id === 2)
        );
        setProduct(
          await result2[0].Sections.find((section) => section.PH_Id === 5)
        );

        // Fetching Banner Data from API

        // const bannerResult = await fetch(
        //   "https://union.kidznteen.pk/public/api/slider"
        // );

        // if (!bannerResult.ok) {
        //   throw new Error("Network response was not ok for Menu Items");
        // }

        // const bannerData = await bannerResult.json();

        // setBanner(
        //   await bannerData.find((page_id) => page_id.Slider_Id === 1)
        // );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  if (loading) {
    return (
      <div className="Loader-Custom">
        <Loader
          type="bubble-ping"
          bgColor={"#DC9553"}
          color={"#000"}
          size={100}
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <SEO title={seo[0].Page_Title} description={seo[0].Meta_Description} />
      <main className="main-wrapper">
        <BannerFive />
        <div className="section section-padding homepageSecion01">
          <div className="horizontal-line"></div>
          <div className="container px-200">
            <div className="vertical-line"></div>
            <div className="circle"></div>
            <SectionTitle
              title={aboutUs.Content_Title}
              description={aboutUs.Content}
              textAlignment="heading-left mb--20 mb_md--70"
              textColor=""
            />
          </div>
        </div>
        <HistorySection history={history} />
        <CTASection></CTASection>
        <Slider product={product}></Slider>
        <CertificateSection></CertificateSection>
        <ContactUsSection></ContactUsSection>
      </main>
    </>
  );
};

export default Home;
