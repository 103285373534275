import React, { useRef, useState } from "react";
// import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const FormFour = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [result, showresult] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://union.kidznteen.pk/public/api/form_store', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        console.log('Data submitted successfully');
        form.current.reset();
        // Optionally, you can reset the form after successful submission
        setFormData({
          name: '',
          email: ''
          // Reset other fields as needed
        });
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form ref={form} onSubmit={handleSubmit} className="axil-contact-form">
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          name="contact-name"
          placeholder="Name"
        //   value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          className="form-control"
          name="contact-email"
          placeholder="Email Address"
        //   value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <input
          type="tel"
          className="form-control"
          name="contact-phone"
          placeholder="Phone Number"
        //   value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group align-end">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary btn-height"
          name="submit-btn"
        >
          Submit
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormFour;
