import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { FaAngleDown } from "react-icons/fa";
import { FiPlus, FiMinus } from "react-icons/fi";

const Nav = ({ menu }) => {
  const [openSubMenuId, setOpenSubMenuId] = useState(null);

  const handleSubMenuClick = (itemId) => {
    if (openSubMenuId === itemId) {
      // Clicked on currently open submenu, so close it
      setOpenSubMenuId(null);
    } else {
      // Clicked on a different submenu, so open it
      setOpenSubMenuId(itemId);
    }
  };

  const basePath = window.location.origin;
  const fullPath = window.location.href;

  // Subtracting the base path from the full path
  const relativePath = fullPath.substring(basePath.length);
  const formattedPath = relativePath.startsWith("/")
    ? relativePath.substring(1)
    : relativePath;
  menu.forEach((menuItem) => {
    if (menuItem.Sub_Menu.length > 0) {
      // Change the link for items with submenus
      menuItem.Link = formattedPath; // Replace "New_Link" with the desired new link
    }
  });

  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        {menu.map((item) => (
          <li
            key={item.Menu_Id}
            className={item.Sub_Menu.length > 0 ? "menu-item-has-children" : ""}
          >
            <Link
              to={process.env.PUBLIC_URL + `/${item.Link}`}
              onClick={() => handleSubMenuClick(item.Menu_Id)}
            >
              {item.Menu_Name}
              {item.Sub_Menu.length > 0 &&
                (openSubMenuId === item.Menu_Id ? <FiMinus /> : <FiPlus />)}
            </Link>

            {item.Sub_Menu.length > 0 && (
              <ul
                className={
                  openSubMenuId === item.Menu_Id
                    ? "axil-submenu active"
                    : "axil-submenu"
                }
              >
                {/* <ul className="axil-submenu"> */}
                {item.Sub_Menu.map((subItem) => (
                  <li key={subItem.SubMenu_Id}>
                    <Link to={process.env.PUBLIC_URL + `/${subItem.Link}`}>
                      {subItem.Name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
