import React from "react";

const GalleryCard3 = ({ Image, Title, Content }) => {
  return (
    <div className="gallery3-card">
      <img src={process.env.PUBLIC_URL + Image} alt={Content} />
      <div className="gallery3-card-content">
        <h4
          dangerouslySetInnerHTML={{
            __html: Title.replace(/\s+/g, "<br/>"),
          }}
        ></h4>
        <p>{Content}</p>
      </div>
    </div>
  );
};

const Gallery3 = ({ images }) => {
  return (
    <>
      <div className="gallery-section mb-5">
        <div className="gallery-section-introduction">
          <div className="gallery-container">
            <div className="gallery3-cards pt-4">
              {images.map((award, index) => (
                <GalleryCard3 key={index} {...award} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery3;
