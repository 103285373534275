import React from "react";
import { GoPaperclip } from "react-icons/go";

const HistoryCard = ({ item }) => {
  const { Title, Content } = item;

  return (
    <div className="historyItem">
      <div className="historyBorder">
        <div className="historyCircle"></div>
        <div className="historyVertical-line"></div>
      </div>
      <div className="historyContent">
        <div className="historyContentIcon">
          <GoPaperclip />
        </div>
        <div className="historyContentYear">
          <strong>{Title}</strong>
        </div>
        <div className="historyContentDesc">{Content}</div>
      </div>
    </div>
  );
};

export default HistoryCard;
