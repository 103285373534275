import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Components
import HeaderFour from "./common/header/HeaderFour";
import FooterThree from "./common/footer/FooterThree";

// Loader
import Loader from "react-js-loader";

// Pages
// Home Page
import Home from "./pages/Home";

// Who We Are
import History from "./pages/Who We Are/History";
import Leadership from "./pages/Who We Are/Leadership";
import Awards from "./pages/Who We Are/Awards";

// Out Products
import DropProof from "./pages/Our Products/DropProof";
import MattressTicking from "./pages/Our Products/MattressTicking";
import InstitutionalFabrics from "./pages/Our Products/InstitutionalFabrics";
import HomeTextiles from "./pages/Our Products/HomeTextiles";
import DyedPrinted from "./pages/Our Products/DyedPrinted";

// Business Function
import ProcessingFinishing from "./pages/Business Function/ProcessingFinishing";
import Stitching from "./pages/Business Function/Stitching";
import Weavingknitting from "./pages/Business Function/Weavingknitting";

// Corporate Social Responsibility
import CSR from "./pages/CSR";

// Exhibition
import Exhibition from "./pages/Exhibition";

// Careers
import Careers from "./pages/Careers";

// Contact Us
import ContactUs from "./pages/ContactUs";

// SCSS
import "./assets/scss/app.scss";

function App() {
  // Loading and error variables
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Menu Data
  const [menu, setMenu] = useState(null);

  // Banner Data
  const [historyBanner, setHistoryBanner] = useState(null);
  const [leadershipBanner, setLeadershipBanner] = useState(null);
  const [awardsBanner, setAwardsBanner] = useState(null);
  const [dropBanner, setDropBanner] = useState(null);
  const [dyedBanner, setDyedBanner] = useState(null);
  const [textilesBanner, setTextilesBanner] = useState(null);
  const [instBanner, setInstBanner] = useState(null);
  const [mattressBanner, setMattressBanner] = useState(null);
  const [careersBanner, setCareersBanner] = useState(null);
  const [contactBanner, setContactBanner] = useState(null);
  const [csrBanner, setCsrBanner] = useState(null);
  const [exhiBanner, setExhiBanner] = useState(null);
  const [processBanner, setProcessBanner] = useState(null);
  const [stitchingBanner, setStitchingBanner] = useState(null);
  const [weavingBanner, setWeavingBanner] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Menu API call
        const menuResult = await fetch(
          "https://union.kidznteen.pk/public/api/menus"
        );
        if (!menuResult.ok) {
          throw new Error("Network response was not ok for Menu Items");
        }

        const menu = await menuResult.json();
        setMenu(menu);

        const bannerResult = await fetch(
          "https://union.kidznteen.pk/public/api/slider"
        );

        if (!bannerResult.ok) {
          throw new Error("Network response was not ok for Menu Items");
        }

        const banner = await bannerResult.json();

        setHistoryBanner(
          await banner.find((page_title) => page_title.Title === "Our History")
        );

        setLeadershipBanner(
          await banner.find(
            (page_title) => page_title.Title === "Our Leadership"
          )
        );

        setAwardsBanner(
          await banner.find((page_title) => page_title.Title === "Awards")
        );

        setTextilesBanner(
          await banner.find(
            (page_title) =>
              page_title.Title ===
              "Elevate Your Living Spaces with Unmatched Quality"
          )
        );

        setDropBanner(
          await banner.find(
            (page_title) => page_title.Title === "Drop Proof Fabric"
          )
        );

        setDyedBanner(
          await banner.find(
            (page_title) => page_title.Title === "Dyed and Printed Fabric"
          )
        );

        setInstBanner(
          await banner.find(
            (page_title) => page_title.Title === "Institutional Fabric"
          )
        );

        setMattressBanner(
          await banner.find(
            (page_title) => page_title.Title === "Mattress Ticking"
          )
        );

        setCareersBanner(
          await banner.find((page_title) => page_title.Title === "Careers")
        );

        setContactBanner(
          await banner.find((page_title) => page_title.Title === "Contact Us")
        );

        setCsrBanner(
          await banner.find(
            (page_title) =>
              page_title.Title === "Corporate Social Responsibility (CSR)"
          )
        );

        setExhiBanner(
          await banner.find((page_title) => page_title.Title === "Exhibitions")
        );

        setProcessBanner(
          await banner.find(
            (page_title) => page_title.Title === "Processing & Finishing"
          )
        );

        setStitchingBanner(
          await banner.find(
            (page_title) => page_title.Title === "Stitching & Quality Assurance"
          )
        );

        setWeavingBanner(
          await banner.find(
            (page_title) => page_title.Title === "Weaving & Knitting"
          )
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  if (loading) {
    return (
      <div className="Loader-Custom">
        <Loader
          type="bubble-ping"
          bgColor={"#DC9553"}
          color={"#000"}
          size={100}
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <Router>
      <ScrollToTop>
        <div>
          <HeaderFour menu={menu} />
          <div>
            <Routes>
              <Route path={process.env.PUBLIC_URL + ""} element={<Home />} />
              <Route
                path={process.env.PUBLIC_URL + "who-we-are/history"}
                element={<History banner={historyBanner} />}
              />
              <Route
                path={process.env.PUBLIC_URL + "who-we-are/leadership"}
                element={<Leadership banner={leadershipBanner} />}
              />
              <Route
                path={process.env.PUBLIC_URL + "who-we-are/awards"}
                element={<Awards banner={awardsBanner} />}
              />
              <Route
                path={process.env.PUBLIC_URL + "products/mattress-ticking"}
                element={<MattressTicking banner={mattressBanner} />}
              />
              <Route
                path={
                  process.env.PUBLIC_URL +
                  "products/institutional-fabric-and-made-ups"
                }
                element={<InstitutionalFabrics banner={instBanner} />}
              />
              <Route
                path={process.env.PUBLIC_URL + "products/home-textiles"}
                element={<HomeTextiles banner={textilesBanner} />}
              />
              <Route
                path={
                  process.env.PUBLIC_URL + "products/dyed-and-printed-fabric"
                }
                element={<DyedPrinted banner={dyedBanner} />}
              />
              <Route
                path={process.env.PUBLIC_URL + "products/drop-proof-fabric"}
                element={<DropProof banner={dropBanner} />}
              />
              <Route
                path={
                  process.env.PUBLIC_URL +
                  "business-function/processing-finishing"
                }
                element={<ProcessingFinishing banner={processBanner} />}
              />
              <Route
                path={process.env.PUBLIC_URL + "business-function/stitching"}
                element={<Stitching banner={stitchingBanner} />}
              />
              <Route
                path={
                  process.env.PUBLIC_URL + "business-function/weaving-knitting"
                }
                element={<Weavingknitting banner={weavingBanner} />}
              />
              <Route
                path={
                  process.env.PUBLIC_URL + "corporate-social-responsibility"
                }
                element={<CSR banner={csrBanner} />}
              />
              <Route
                path={process.env.PUBLIC_URL + "exhibition"}
                element={<Exhibition banner={exhiBanner} />}
              />
              <Route
                path={process.env.PUBLIC_URL + "Careers"}
                element={<Careers banner={careersBanner} />}
              />
              <Route
                path={process.env.PUBLIC_URL + "Contact-Us"}
                element={<ContactUs banner={contactBanner} />}
              />
            </Routes>
          </div>
          <FooterThree />
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
