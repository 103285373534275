import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import { ReactComponent as MenuLogo } from "../../assets/icons/back-svgrepo-com.svg";
// import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook-svgrepo-com.svg";
// import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram-167-svgrepo-com.svg";
import { useLocation } from "react-router-dom";

const MobileMenu = ({ MobileHandler, menu }) => {
  const innerDiv = useRef(null);
  const menuDiv = useRef(null);
  const location = useLocation();

  const handleClick = (event) => {
    const target = event.target;
    // Check if the click is inside myDiv
    if (menuDiv.current.contains(target)) {
      if (innerDiv.current && innerDiv.current.contains(target)) {
      } else {
        MobileHandler();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    document.querySelector(".mobilemenu-popup").classList.remove("show");
  }, [location.pathname]);

  return (
    <div className="mobilemenu-popup" ref={menuDiv}>
      <div className="mobilemenu-inner" ref={innerDiv}>
        <div className="mobilemenu-justify-spacebetween">
          <div>
            <div className="mobilemenu-header">
              {/* <h4 className="welcome">Welcome</h4> */}
              <div className="mobile-nav-logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img
                    className="light-mode"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/logo/Union-Logo-light-menu.png"
                    }
                    alt="Site Logo"
                    width={200}
                  />
                  {/* <img
                    className="dark-mode"
                    src={process.env.PUBLIC_URL + "/images/logo/union-logo-light.png"}
                    alt="Site Logo"
                    width={80}
                  /> */}
                </Link>
              </div>
              <button className="mobile-menu-close" onClick={MobileHandler}>
                <MenuLogo width={50} height={50} fill="white" />
              </button>
            </div>
            <div className="mobilemenu-body">
              <Nav menu={menu} />
            </div>
          </div>

          {/* Social Icons Start */}
          <div className="mobileMenu-bottom">
            <div className="menu-social-link">
              <ul className="list-unstyled">
                <li>
                  <Link to="https://instagram.com/">
                    <img
                      width={30}
                      height={30}
                      src={process.env.PUBLIC_URL + "/images/icon/instagram.png"}
                      alt="instagram"
                    ></img>
                    {/* <InstagramIcon width={50} height={50} fill="white" /> */}
                  </Link>
                </li>
                <li>
                  <Link to="https://www.facebook.com/">
                    <img
                      width={30}
                      height={30}
                      src={process.env.PUBLIC_URL + "/images/icon/facebook.png"}
                      alt="facebook"

                    ></img>
                    {/* <FacebookIcon width={50} height={50} fill="white" /> */}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* Social Icons End */}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
