import React from "react";

const ProcessCard = ({ item }) => {
  const { Title, Content } = item;
  // Split the string into an array based on the newline character (\n)
  const splitArray = Content.split("\n");

  // Extract variables
  const processTitle = splitArray[0];
  const processDesc = splitArray[1];

  return (
    <div className="processItem">
      <div className="processBorder">
        <div className="processCircle"></div>
        <div className="processVertical-line"></div>
      </div>
      <div className="processContent">
        <div className="processContentIcon">
          <strong>{Title}</strong>
        </div>
        <div className="processContentYear">
          <strong>{processTitle}</strong>
        </div>
        <div className="processContentDesc">{processDesc}</div>
      </div>
    </div>
  );
};

export default ProcessCard;
