import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import ContactUsSection from "../../component/contact/ContactUsSection";
import BannerFour from "../../component/banner/BannerFour";
import Introduction from "../../component/introduction/Introduction";

// Loader
import Loader from "react-js-loader";

const LeadershipCard = ({ Image, Title, Content }) => (
  <div className="leadership-card">
    <img src={Image} alt={Content} />
    <h4>{Title}</h4>
    <p>{Content}</p>
  </div>
);

const Leadership = () => {
  const [content, setContent] = useState(null);
  const [leadership, setLeadership] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [banner,setBanner] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API call
        const pageData = await fetch(
          "https://union.kidznteen.pk/public/api/pages/2/2"
        );
        if (!pageData.ok) {
          throw new Error("Network response was not ok for Homepage Content");
        }
        const result = await pageData.json();
        const leadershipPage = await result.find((page) => page.Page_Id === 3);

        setContent(leadershipPage);

        setLeadership(
          await leadershipPage.Sections.find((section) => section.PH_Id === 10)
        );
        // Fetching Banner Data from API

        const bannerResult = await fetch(
          "https://union.kidznteen.pk/public/api/slider"
        );

        if (!bannerResult.ok) {
          throw new Error("Network response was not ok for Menu Items");
        }

        const bannerData = await bannerResult.json();

        setBanner(
          await bannerData.find((page_title) => page_title.Slider_Id === 3)
        );

      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  if (loading) {
    return (
      <div className="Loader-Custom">
        <Loader
          type="bubble-ping"
          bgColor={"#DC9553"}
          color={"#000"}
          size={100}
        />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  
  return (
    <>
      <SEO title={content.Page_Title} description={content.Meta_Description} />
      <main className="main-wrapper">
        <BannerFour
          title={banner.Title}
          description={banner.Desc}
          image={`url(${banner.Image})`}
        />
        <Introduction introduction={leadership.Content} />

        <div className="leadearship-sections mb-5 pb-5">
          <div className="leadership-cards">
            {leadership.More_Detail.map((leader, index) => (
              <LeadershipCard key={index} {...leader} />
            ))}
          </div>
        </div>

        <ContactUsSection></ContactUsSection>
      </main>
    </>
  );
};

export default Leadership;
