import React from "react";
import { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import ProcessCard from "./ProcessCard";

const Process = ({ process }) => {
  const sliderArray = process?.More_Detail || [];

  const [numColumns, setNumColumns] = useState(sliderArray.length);
  const [width, setWidth] = useState(0);
  const dragSlider = useRef();

  useEffect(() => {
    setWidth(dragSlider.current.scrollWidth - dragSlider.current.offsetWidth);
  }, []);

  return (
    <div className="processSection">
      <div className="processBox">
        <motion.div className="sliderItems" ref={dragSlider}>
          <motion.div
            ref={dragSlider}
            className="sliderItem"
            drag="x"
            dragConstraints={{ right: 0, left: -width }}
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${numColumns}, 20.4rem)`,
              gap: "1rem",
            }}
          >
            {sliderArray.map((item, index) => (
              <ProcessCard key={index} item={item}></ProcessCard>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Process;
