import React from "react";

const BannerFour = ({ title, description, image }) => {
  return (
    <div
      className="banner banner-style-4"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${image}`,
      }}
    >
      <div className="container">
        <div className="banner-content">
          <h1 className="title">{title}</h1>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default BannerFour;
