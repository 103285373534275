import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const PostCard = ({
  Head_Title,
  Content_Title,
  Content,
  Image,
  More_Detail,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handlePopupClose = () => {
    setOpenDialog(false);
  };

  const handlePopupOpen = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <div className="post">
        <a href="#" style={{ display: "flex" }} onClick={handlePopupOpen}>
          <div className="post-image">
            <img src={Image} alt={Head_Title}></img>
          </div>
          <div className="post-content">
            <h5>{Content_Title}</h5>
            <p>{Content}</p>
          </div>
        </a>

        <Dialog open={openDialog} onClose={handlePopupClose} className="Dialog">
          <DialogTitle>{More_Detail[0].Title}</DialogTitle>
          <DialogContent
            className="DialogContent"
            style={{ overflow: "hidden" }}
          >
            <p>{More_Detail[0].Content}</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={More_Detail[0].Image}
                alt={More_Detail[0].Title}
                width={300}
              ></img>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const JobPost = ({ jobPost }) => {
  return (
    <>
      <div className="job-post-container mt-5 mb-5">
        <div className="job-post">
          {jobPost.map((post, index) => (
            <PostCard key={index} {...post} />
          ))}
        </div>
      </div>
    </>
  );
};

export default JobPost;
